import React, { useEffect, useState } from "react";
import logo1 from "../../assets/Skill-Stream-dark.jpg";
import NewLogo from "../../assets/new-gif-skill.gif";

const Footer = () => {
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("Role");

  useEffect(() => {
    const scrollButton = document.querySelector(".scroll-to-top");

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Show/hide the scroll-to-top button based on scroll position
      if (scrollY > 300) {
        scrollButton.style.display = "block";
      } else {
        scrollButton.style.display = "none";
      }
    };

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Attach event listeners
    window.addEventListener("scroll", handleScroll);
    scrollButton.addEventListener("click", scrollToTop);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      scrollButton.removeEventListener("click", scrollToTop);
    };
  }, []);

  // const [text, setText] = useState("");
  // const fullText = "Where Skills and Opportunities align";

  // useEffect(() => {
  //   let currentIndex = 0;

  //   const interval = setInterval(() => {
  //     if (currentIndex <= fullText.length) {
  //       setText(fullText.substring(0, currentIndex));
  //       currentIndex++;
  //     } else {
  //       currentIndex = 0; // Reset currentIndex to restart typing animation
  //     }
  //   }, 100); // Adjust typing speed here (milliseconds)

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <footer class="main-footer">
      <div class="auto-container">
        <div class="widgets-section wow fadeInUp">
          <div class="row">
            <div class="big-column col-xl-4 col-lg-3 col-md-12">
              <div class="footer-column about-widget">
                <div className="logo-footer">
                  <a href="/">
                    <img
                      src={NewLogo}
                      style={{ height: "50px" }}
                      className="logo-images"
                      alt=""
                      title=""
                    />
                    {/* <span
                      style={{
                        color: "#1E90FF",
                        fontSize: "18px",
                        fontWeight: 700,
                      }}
                    >
                      SkillStreamJobs
                    </span>
                    <span
                      style={{
                        color: "#00d0ea",
                        fontSize: "20px",
                        fontWeight: "900",
                      }}
                    >
                      .AI
                    </span> */}
                  </a>
                </div>
                <br />
              </div>
            </div>

            <div class="big-column col-xl-8 col-lg-9 col-md-12">
              <div class="row">
                {/*      {token ? (
                  <>
          
                    <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                      <div class="footer-widget links-widget">
                        <h4 class="widget-title">For Candidates</h4>
                        <div class="widget-content">
                          <ul class="list">
                            <li>
                              <a href="/find-jobs">Find Jobs</a>
                            </li>

                            <li>
                              <a href="/job-alerts">Job Alerts</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                      <div class="footer-widget links-widget">
                        <h4 class="widget-title">For Employers</h4>
                        <div class="widget-content">
                          <ul class="list">
                            <li>
                              <a href="/applicants">Find Candidates</a>
                            </li>
                            <li>
                              <a href="/post-Job">Post a Job</a>
                           
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}*/}

                <div class="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div class="footer-widget links-widget">
                    <h4 class="widget-title">About Us</h4>
                    <div class="widget-content">
                      <ul class="list">
                        {/* <li>
                          <a href="#">Job Page</a>
                        </li>
                        <li>
                          <a href="#">Job Page Alternative</a>
                        </li> */}
                        {/* <li>
                          <a href="#">Resume Page</a>
                        </li> */}
                        <li>
                          <a href="/about">About</a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="footer-column col-lg-4 col-md-6 col-sm-8">
                  <div class="footer-widget links-widget">
                    <h4 class="widget-title">Helpful Resources</h4>
                    <div class="widget-content">
                      <ul class="list">
                        <li>
                          <a href="/contact">Site Map</a>
                        </li>
                        <li>
                          <a href="/termsAndCondition">Terms of Use</a>
                        </li>
                        <li>
                          <a href="/privacyCenter">Privacy Center</a>
                        </li>
                        <li>
                          <a href="/securityCenter">Security Center</a>
                        </li>
                        {/* <li>
                          <a href="#">Accessibility Center</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="footer-column col-lg-4 col-md-6 col-sm-12">
                  <div class="footer-widget links-widget">
                    <p class="phone-num">
                      <h4 class="widget-title">Reach us </h4>
                      {/* <a href="thebeehost%40support.html">123 456 7890</a> */}
                      <a href="mailto:support@skillstreamjobs.ca" class="email">
                        support@skillstreamjobs.ca
                      </a>
                      <p class="address">
                        Toronto Eaton center 220 Yonge st,
                        <br /> Toronto
                        {/* <a href="mailto:support@skillstreamjobs.ca" class="email">
  ssupport@skillstreamjobs.ca
</a> */}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="auto-container">
          <div class="outer-box">
            <div class="copyright-text">
              © 2024 <a href="/">Skill Stream</a>. All Right Reserved.
            </div>
            <div class="social-links">
              <a href="https://www.facebook.com/profile.php?id=61563887762159" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
              {/* <a href="#">
              import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg"; 
                <i class="fab fa-twitter-t"></i>
              </a>*/}
              <a href="https://www.instagram.com/skillstream_jobs/" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/104131262/admin/dashboard/" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="fa fa-angle-up"></span>
      </div>
    </footer>
  );
};

export default Footer;
